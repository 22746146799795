import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const MetaTags = ({ metaData }) => {
    const currUrl = useLocation();
    console.log('metaData----->', metaData);
    // useEffect(()=>{
    //     console.log('currUrl....',currUrl);
    // },[]);
    return (
        <div>
            {metaData && <Helmet
                title={metaData?.title}
                onChangeClientState={(newState) => {
                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute('content', metaData?.description || '');
                    }
                }}
            >
                { /* Standard metadata tags */}
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta property="image" content="https://www.truehomes24.com/assets/dynamic/logo/3231ba59af210a5c3273fb2440e10cd6.jpg" />
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                {/* <meta property="og:type" content={type} /> */}
                <meta property="og:title" content={metaData?.title} />
                <meta property="og:description" content={metaData?.description} />
                <meta property="og:image" content="https://www.truehomes24.com/assets/dynamic/logo/3231ba59af210a5c3273fb2440e10cd6.jpg" />
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={metaData?.title} />
                {/* <meta name="twitter:card" content={type} /> */}
                <meta name="twitter:title" content={metaData?.title} />
                <meta name="twitter:description" content={metaData?.description} />
                
                { /* End Twitter tags */}
                {/* <title>{metaData?.title}</title>
                <meta property="og:description" content={metaData?.description} />
                <meta name="description" content={metaData?.description} data-react-helmet="true" />
                <meta name="keywords" content={metaData?.keyword} />
                <meta name="language" content="en-us, english" />
                <meta http-equiv="Content-Language" content="en" />
                <meta name="author" content="TrueHomes24.com" />
                <meta name="copyright" content="https://www.truehomes24.com/" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={"https://www.truehomes24.com" + currUrl?.pathname} />
                <meta property="og:title" content={metaData?.title} />
                <meta property="og:url" content={"https://www.truehomes24.com" + currUrl?.pathname} />
                {/* <meta property="og:description" content={metaData?.description} /> */}
                {/* <meta property="og:image" content="https://www.truehomes24.com/assets/dynamic/logo/3231ba59af210a5c3273fb2440e10cd6.jpg" /> */}
            </Helmet>}
        </div>
    );
}

export default MetaTags;

import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import { styles } from '../../Styles/Styles';
import { LocationIcon, MenuIcon } from '../../components/svgIcons';
// import userIcon from '../../assets/images/user.svg'
import RecentViewCard from '../../components/RecentViewCard';
import Carousel from 'react-multi-carousel';
import RightListCard from '../../components/RightListCard';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import FAQs from '../../components/FAQs';
import Contact from '../../components/Contact';
import loader from '../../assets/Icons/loader.gif';

// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";

// import LightGallery from 'lightgallery/react';

// // import styles
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';

// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import useApi, { UseApi } from '../../ApiConf';
import PropertySlider from '../../components/PropertySlider';
import NearByLocation from '../../components/NearByLocation';
import MetaTags from '../../components/MetaTags';
import Skeleton from 'react-loading-skeleton';
import SimilarListing from '../../components/SimilarListing';

// const images = [
//   {
//     original: "https://picsum.photos/id/1018/1000/600/",
//     thumbnail: "https://picsum.photos/id/1018/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1015/1000/600/",
//     thumbnail: "https://picsum.photos/id/1015/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1019/1000/600/",
//     thumbnail: "https://picsum.photos/id/1019/250/150/",
//   },
// ];

// const whatsNearbyData = [
//     // { item: 'Hospital: 2', icon: 'fa-solid fa-house-chimney-medical' },
//     { item: 'Hospital: 2', icon: 'fas fa-hospital' },
//     // { item: 'ATM: 1', icon: 'fa-solid fa-credit-card' },
//     { item: 'ATM: 1', icon: 'fas fa-credit-card' },
//     // { item: 'School: 2', icon: 'fa-solid fa-school' },
//     { item: 'School: 2', icon: 'fas fa-school' },
//     { item: 'Bank: 2', icon: 'fa-sharp fa-solid fa-building-columns' },
//     { item: 'Restaurant: 1', icon: 'fa-solid fa-utensils' },
//     { item: 'Spa Beauty Salon: 2', icon: 'fa-solid fa-spa' },
// ];
// const nearByPlacesType = [
//     { label: 'Bus Stand', value: 'bus_station' },
//     { label: 'School', value: 'school' },
//     { label: 'Hospital', value: 'hospital' },
//     { label: 'ATM', value: 'atm' },
//     { label: 'Bank', value: 'bank' },
//     { label: 'Park', value: 'park' },
//     { label: 'Pharmacy', value: 'pharmacy' },
//     // { label: 'Salon', value: 'salon' },
//     { label: 'Restaurant', value: 'restaurant' },
//     { label: 'Airport', value: 'airport' }

// ];

// const PropertyBarNames = [
//     'Details', 'Description', 'About Builder', 'Gallery', 'Nearby', 'FAQ'
// ];
// const htmlString = "<i class=\"fas fa-life-ring\"></i> Lift";

// const Amenities = [
//     // { name: 'Lift', icon: 'fa-solid fa-elevator' },
//     { name: 'Lift', icon: 'fas fa-life-ring' },
//     { name: 'Security', icon: 'fa-solid fa-shield-halved' },
//     { name: 'CCTV Camera', icon: 'fa-solid fa-video' },
//     // { name: 'Maintenance Staff',icon:'fa-solid fa-staff' },
//     { name: 'Swimming Pool', icon: 'fa-solid fa-water-ladder' },
//     { name: 'Gym', icon: 'fa-solid fa-dumbbell' },
//     { name: 'Visitor Parking', icon: 'fa-solid fa-square-parking' },
//     { name: 'Jogging Track', icon: 'fas fa-running' },
//     // { name: 'Kids Play Area',icon:'fa-solid fa-bench-tree' },
//     // { name: 'Kids Play Pool',icon:'fa-solid fa-bench-tree' },
//     { name: 'Power Backup', icon: 'fa-solid fa-power-off' },
//     { name: 'Large Open Space', icon: '' },
//     { name: 'Internet Connectivity', icon: '' },
//     { name: '24by7 Water', icon: '' },
//     { name: 'Waste Disposal', icon: '' },
//     { name: 'RainWater Harvesting', icon: '' },
//     { name: 'Gated Society', icon: '' },
//     { name: 'Laundry Services', icon: '' },
// ]


const ProjectDetails = () => {
    const [propDetailsTypeInd, setPropDetailsTypeInd] = useState(0);
    const [navClassState, setNavClassState] = useState('');
    const [contactModalStatus, setcontactModalStatus] = useState({ show: false, data: {} });
    const [open, setOpen] = useState(false);
    const [currSlide, setCurrSlide] = useState(1);
    const observerElement = useRef();
    const Details = useRef();
    const Description = useRef();
    const AboutBuilder = useRef();
    const Gallery = useRef();
    const Nearby = useRef();
    const FAQ = useRef();
    const OtherProjects = useRef();
    const routePath = useLocation();
    // const { fetchData } = useApi();
    const { FetchData } = UseApi();
    const [AllData, setAllData] = useState({ breadcrumb: [], gallery: [], data: null, featuredProperty: [], recentlyAddedProperty: [], recentBlogs: null, similarListing: [], otherProjects: [], meta: null });
    const [loading, setLoading] = useState(true);
    const [propertyTabNames, setPropertyTabNames] = useState([]);
    const [galleryImages, setGalleryImges] = useState({ images: [], tabName: null });
    // const [center, setCenter] = useState({ lat: 0, lng: 0 });
    // const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [projectId, setProjectId] = useState('');
    const navigate = useNavigate();
    const [currPlayVideo, setCurrPlayVideo] = useState(null);
    const topSection = useRef(null);

    useEffect(() => {
        ovserveIntersection();
        // getProjectDetails()
    }, []);
    useEffect(() => {
        let arr = routePath.pathname.split('/');
        if (arr.length == 4) {
            setProjectId(arr[3]);
        }
        getProjectDetails();
        topSection?.current?.scrollIntoView({ behavior: 'smooth' });
        // window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [routePath.pathname]);

    const getProjectDetails = async () => {
        setLoading(true);
        let data;
        try {
            data = await FetchData(`${routePath.pathname}`, 'GET');
        } catch (err) {
            console.log(err);
        }
        console.log('data project details...', data);
        if (data && !data?.error) {
            setAllData({
                breadcrumb: data?.breadcrumb,
                gallery: data.data?.galleryTab,
                data: data.data,
                featuredProperty: data.featuredProperty,
                recentlyAddedProperty: data.recentlyAddedProperty,
                recentBlogs: data.recentBlogs,
                similarListing: data.similarListing,
                otherProjects: data.otherProjects ? data.otherProjects : [],
                meta: data.meta
            });
            data?.data?.galleryTab?.images ? setGalleryImges({ images: data?.data?.galleryTab?.images, tabName: null }) : setGalleryImges({ images: data?.data?.galleryTab?.PropertyImages, tabName: 'PropertyImages' });
            setPropertyTabNames([
                { name: 'Details', sectionName: Details, show: true },
                { name: 'Description', sectionName: Description, show: true },
                { name: 'About Builder', sectionName: AboutBuilder, show: AllData.data?.descriptionTab?.aboutBuilder ? true : false },
                { name: 'Gallery', sectionName: Gallery, show: true },
                { name: 'Nearby', sectionName: Nearby, show: data.data?.nearByTab?.nearby ? true : false },
                { name: 'FAQ', sectionName: FAQ, show: data.data?.faqtab?.length > 0 || data.data?.faqtab?.faqs.length > 0 },
                { name: 'Other Projects', sectionName: OtherProjects, show: data.otherProjects?.length > 0 },
            ]);
            // if(data?.data?.location){
            //     fetchCoordinates(data?.data.location);
            // }
        } else if (data?.error && data?.redirect) {
            navigate(`/${data?.redirect}`);
        }
        setLoading(false);
    }

    // const fetchCoordinates = async (address) => {
    //     try {
    //         const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA`);
    //         const data = await response.json();
    //         console.log('fetchcordinates data...', data);
    //         if (data.results && data.results.length > 0) {
    //             const { lat, lng } = data.results[0].geometry.location;
    //             setCenter({ lat, lng });
    //             performNearbySearch(data.results[0].geometry.location, nearByType);
    //             // fetchNearbyPlaces(data.results[0].geometry.location);
    //         } else {
    //             console.error('No results found');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching coordinates:', error);
    //     }
    // };

    const ovserveIntersection = () => {
        let observer = new IntersectionObserver((entries) => {
            if (!entries[0].isIntersecting) {
                setNavClassState('fixed top-0 left-0 w-screen  shadow-md z-[1500] pr-[10%] bg-white');
            }
            else {
                setNavClassState('');
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0
        });
        observer.observe(observerElement.current);
    }

    const onClickContactBtn = () => {
        console.log('AllData.data...', AllData.data);
        if (!AllData.data) return;
        let arr = AllData.data.propId ? AllData.data.propId.split('-') : AllData.data.projectId.split('-');
        let propId = parseInt(arr[1], 10).toString();
        let cityarr = AllData.data.bannerTitle.split(',');
        setcontactModalStatus({
            show: true, data: {
                owner: AllData.data?.userDetails?.name,
                type: AllData.data?.btnParams[2],
                icon: AllData.data?.userDetails?.image,
                propId: propId,
                city: cityarr[cityarr.length - 1],
                isNewProject: AllData.data.projectId ? '1' : '0'
            }
        });
    }
    const onCloseContact = () => {
        setcontactModalStatus({ show: false, data: null });
    }

    console.log('AllData------->', AllData);
    return (
        <div >
            {navClassState === '' && <Header />}
            { <MetaTags metaData={AllData.meta} />}
            <div ref={topSection} className={'bg-gray-50 py-5 ' + (loading && 'opacity-50')}>
                {/* <div className='bg-white h-[100px]'>
                </div> */}
                <div className='mt-14 container mx-auto px-2 sm:px-10'>
                    {AllData?.breadcrumb && <div className='text-sm px-2 text-gray-500'> <NavLink to={'/'}>Home</NavLink>{' > '}<span>{AllData?.breadcrumb[1]?.title}</span>{' > '}<span>{AllData.breadcrumb[2]?.title}</span>
                        {AllData?.breadcrumb[3]?.title && ' > '}<span className='text-base'>{AllData?.breadcrumb[3]?.title}</span>
                    </div>}
                    {loading && <div className="fixed top-[100px] right-1/2 flex justify-center items-center mt-16">
                        <img alt="Please wait.." title="Please wait.." src={loader} />
                    </div>}
                    <div className='bg-white py-5 px-[2%] mt-1 shadow w-full md:flex md:gap-5'>
                        <div className=' w-full md:w-[67%]'>
                            <div className='relative'>
                                {loading ? <Skeleton className='h-[300px] sm:h-[360px] lg:h-[550px] xl:[630px] w-full rounded-xl' /> : <img alt=''
                                    src={AllData.data?.banner}
                                    className='h-[300px] sm:h-[360px] lg:h-[550px] xl:[630px] w-full rounded-xl' />}
                                <div ref={observerElement} className='absolute bottom-16 pl-5 py-2 w-[80%] bg-black bg-opacity-10'>
                                    <p className='text-white text-xl sm:text-3xl'>{AllData.data?.bannerTitle}</p>
                                </div>
                                <div ref={observerElement} className='absolute flex bottom-5 pl-5 py-2 w-[20%] bg-black bg-opacity-10'>
                                    {/* <div className='mr-5'>
                                        <p className='text-white sm:text-1xl'>{AllData.data?.viewCount}</p>
                                    </div> */}

                                    <div style={{ paddingTop: 3, paddingRight: 5 }}>
                                        <svg fill="#fff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.011 512.011" space="preserve" stroke="#fff">

                                            <g id="SVGRepo_bgCarrier" stroke-width="0" />

                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                                            <g id="SVGRepo_iconCarrier"> <g> <g> <g> <path d="M505.755,240.92l-89.088-89.088c-88.576-88.597-232.747-88.597-321.323,0L6.256,240.92 c-8.341,8.341-8.341,21.824,0,30.165l89.088,89.088c44.288,44.288,102.464,66.453,160.661,66.453s116.373-22.165,160.661-66.453 l89.088-89.088C514.096,262.744,514.096,249.261,505.755,240.92z M256.005,362.669c-58.816,0-106.667-47.851-106.667-106.667 s47.851-106.667,106.667-106.667s106.667,47.851,106.667,106.667S314.821,362.669,256.005,362.669z" /> <path d="M256.005,192.003c-35.285,0-64,28.715-64,64s28.715,64,64,64s64-28.715,64-64S291.291,192.003,256.005,192.003z" /> </g> </g> </g> </g>

                                        </svg>
                                    </div>
                                    <p className='text-white sm:text-1xl'>{AllData.data?.viewCount}</p>
                                </div>
                            </div>
                            <div className='xs:flex my-5'>
                                {AllData.data?.bannerFooter && AllData.data?.bannerFooter.map((item, index) => {
                                    return (
                                        <div className={'mt-1 text-center px-2 xs:w-[32%] ' + (index == 1 ? 'border-y-[1px] xs:border-y-0 xs:border-x-2 xs:border-gray-300' : '')}>
                                            <p className='font-semibold'>{item.value}</p>
                                            <p className='text-sm'>{item.label}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='p-2 relative px-0 w-full md:w-[33%]'>
                            {loading ? <Skeleton count={1} className='h-10' /> : <span className={styles.title2 + 'mr-2'}>{AllData.data?.unitConfigurationTab ? AllData.data?.bannerTitle : AllData.data?.propTitle}</span>}
                            {loading ? <Skeleton count={1} className='h-5' /> : <>
                                <span className='mt-2'><button className='cursor-text mt-1 px-2 py-[2px] bg-orange-600 text-xs font-medium opacity-90 text-white mr-1'>{AllData.data?.listedFor?.toUpperCase()}</button></span>
                                <span className='mt-2'><button className='cursor-text mt-1 px-2 py-[2px] bg-orange-600 text-xs font-medium opacity-90 text-white mr-1'>{AllData.data?.brokerage_status?.toUpperCase()}</button></span>
                                <span className=''> <button className='cursor-text mt-1 px-2 py-[2px] bg-cyan-600 text-xs font-medium text-white mr-1'>{AllData.data?.subTypeName?.toUpperCase()}</button></span>
                                <span className=''> <button className='cursor-text mt-1 px-2 py-[2px] bg-cyan-600 text-xs font-medium text-white'>{AllData.data?.unitConfigurationTab ? ('NEW PROJECT ID: ' + projectId) : ('PROPERTY ID: ' + (AllData.data?.propId ? AllData.data?.propId : 'NA'))}</button></span>
                            </>}
                            <div className='mt-1'>
                                {loading ? <Skeleton count={1} className='h-5' /> : <div className='flex mt-5'>
                                    <span className=''>
                                        <LocationIcon classname={'h-6 w-5 mt-[0.5px]'} />
                                    </span>
                                    <span className='text-base text-gray-800 ml-1'>
                                        {AllData?.data?.society && <p className={styles.textMedium + 'text-gray-500 font-medium inline-block'}>Society: </p>}
                                        {AllData?.data?.society ? (' ' + AllData?.data?.society + ', ') : ' '}{(AllData?.data?.location)}
                                    </span>
                                </div>}
                                {!AllData.data?.unitConfigurationTab && <div className='flex gap-1 mt-2'>
                                    {loading ? <Skeleton count={1} className='h-8 w-8 rounded-full' /> : <img alt='' src={AllData.data?.userDetails?.image} className='h-8 w-8 rounded-full' />}
                                    {<NavLink to={`/${AllData.data?.userDetails?.link}`}
                                        className='text-sm text-gray-500 mt-2'>
                                        {loading ? <Skeleton count={1} className='h-8 w-8 rounded-full mt-2' /> : <>{<>{AllData.data?.userDetails?.name} {`(${AllData.data?.btnParams[2]})`}</>}</>}
                                    </NavLink>}
                                </div>}
                                {loading && <div className='grid grid-cols-2 gap-2 mt-2'>
                                    <Skeleton className='h-10' />
                                    <Skeleton className='h-10' />
                                </div>}
                                {loading && <div className='grid grid-cols-2 gap-2 mt-2'>
                                    <Skeleton className='h-10' />
                                    <Skeleton className='h-10' />
                                </div>}

                                {AllData.data?.unitConfigurationTab && !loading && <div className='mt-5'>
                                    <div className='grid grid-cols-2 gap-2'>
                                        {AllData.data?.bedroom && <div className=''>
                                            <p className='text-gray-400 font-semibold text-sm'>BHK</p>
                                            <p className='text-black'>{AllData.data?.bedroom}</p>
                                        </div>}
                                        {AllData.data?.price != '' && AllData.data?.price != '0' && <div className=''>
                                            <p className='text-gray-400 font-semibold text-sm'>Price Range</p>
                                            <p className='text-black'>{AllData.data?.price}</p>
                                        </div>}
                                    </div>
                                    <div className='grid grid-cols-2 gap-2 mt-2'>
                                        {AllData.data?.area && <div className=''>
                                            <p className='text-gray-400 font-semibold text-sm'>Area</p>
                                            <p className='text-black'>{AllData.data?.area}</p>
                                        </div>}
                                        {AllData.data?.subTypeName && <div className=''>
                                            <p className='text-gray-400 font-semibold text-sm'>Property Type</p>
                                            <p className='text-black'>{AllData.data?.subTypeName}</p>
                                        </div>}
                                    </div>
                                    {AllData.data?.status && <div className='mt-4'>
                                        <p className='text-gray-400 font-semibold text-sm'>Project Status</p>
                                        <p className='text-black'>{AllData.data?.status}</p>
                                    </div>}
                                </div>}
                                {/* dangerouslySetInnerHTML={{__html:(AllData.data?.unitConfigurationTab?'Builder Price:':'Sale Price:') && AllData.data?.price}} */}
                                {loading ? <Skeleton count={1} /> : <div className='mt-2 text-sm '>
                                    {AllData.data?.rera_id && <div className='flex flex-wrap '>
                                        <span>RERA ID</span>
                                        <span className='relative group'>
                                            <span className='bg-black text-white p-2 rounded-lg hidden group-hover:block absolute -left-10 w-[200px] top-7'>
                                                RERA detail displayed here, is obtained from the respective State’s RERA website. Truehomes24 is an online platform, publishing info 'As Is' available on RERA websites.
                                            </span>
                                            <img src="https://www.truehomes24.com/assets/dynamic/top_selling/info.png" className='h-4 w-4 mx-1 mt-[1px]' />
                                            <i class="fa-solid fa-caret-down hidden group-hover:block absolute rotate-180 left-1 "></i>
                                        </span>
                                        {/* <i class="fa-solid fa-circle-info fill-white"></i> */}
                                        <span className='text-cyan-600 line-clamp-2'>{AllData.data?.rera_id}</span>
                                    </div>}
                                </div>}
                                {!loading && <div className=' w-full mt-2'>
                                    {(AllData.data?.price && AllData.data?.price != '0') ? <span className=''><button className='cursor-text mt-1 px-2 py-[2px] bg-cyan-600 text-lg text-white w-full text-left rounded'>{AllData.data?.unitConfigurationTab ? 'Builder Price:' : 'Sale Price:'}
                                        {AllData.data?.CurrencyClass ? <i className={AllData.data?.CurrencyClass + ' text-base ml-1'}></i> : AllData.data?.CurrencySymbol} {AllData.data?.convertedPrice ? AllData.data?.convertedPrice : AllData.data?.price}
                                    </button></span>
                                        :
                                        <button onClick={onClickContactBtn} className={styles.btnFull + 'bg-green-600 hover:bg-green-700 mt-5'}>ASK FOR PRICE</button>
                                    }
                                    <button onClick={onClickContactBtn} className={styles.btnFull + 'bg-green-600 hover:bg-green-700 mt-3'}>Request Contact</button>
                                </div>}
                                {loading && <div>
                                    <button className='h-10 w-full'><Skeleton className='h-10 w-full' /></button>
                                    <button className='h-10 w-full mt-2'><Skeleton className='h-10 w-full' /></button>
                                </div>}
                                {AllData.data?.unitConfigurationTab && <div className='flex gap-1 mt-2'>
                                    <img alt='' src={AllData.data?.userDetails?.image} className='h-8 w-8' />
                                    <NavLink
                                        to={`/${AllData.data?.userDetails?.link}`}
                                        className='text-sm text-gray-500 mt-2'>
                                        {AllData.data?.userDetails?.name} {!AllData.data?.unitConfigurationTab ? `(${AllData.data?.btnParams[2]})` : ''}
                                    </NavLink>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        {contactModalStatus.show && <Contact Data={contactModalStatus.data} func={onCloseContact} />}
                    </div>

                    <div className='lg:flex justify-between mt-10'>
                        <div className='w-full lg:w-[65%]'>
                            <div className='bg-white shadow-md px-[2%] py-5 w-full'>
                                <div className={navClassState}>
                                    <div className={(navClassState !== '' ? 'transition-transform ease-in-out transform translate-x-[8%] py-2 duration-[1500ms] border-b-0 ' : ' border-b-[1px]') + ' flex flex-wrap gap-2 border-b-gray-300  px-[2%] -mx-[2%]'}>
                                        {propertyTabNames?.length > 0 && propertyTabNames.map((item, index) => {
                                            return (
                                                <>
                                                    {item.show &&
                                                        <a key={index}
                                                            onClick={() => {
                                                                setPropDetailsTypeInd(index);
                                                                item.sectionName.current?.scrollIntoView({ behavior: 'smooth' })
                                                            }}
                                                            className={(propDetailsTypeInd === index ? 'border-b-[1px] animated-border border-black ' : '') + ' px-1 py-2 cursor-pointer'}>
                                                            {item.name}
                                                        </a>}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div ref={Details} className='scroll-mt-20'>
                                    <p
                                        //  onClick={()=>section.current.scrollIntoView({ behavior: 'smooth' })}
                                        className={styles.title4 + 'mt-8'}>{AllData.data?.scoietyName ? `${AllData.data?.scoietyName} Info` : 'Property Details'}</p>
                                    <div
                                        className='flex justify-between flex-wrap'>
                                        <div
                                            className='w-[50%] sm:w-[30%] mt-2'
                                        //  className='grid-cols-2 sm:grid-cols-3 mt-2'
                                        >
                                            {/* {console.log('AllData.data?.detailsTab....', AllData.data?.detailsTab)} */}
                                            {AllData.data?.detailsTab?.length > 0 && AllData.data?.detailsTab?.map((item, index) => {
                                                return (
                                                    <>
                                                        {index % 3 == 0 && <div key={index} className='mt-1' >
                                                            <span className=''>{item.label}: </span>
                                                            <a className='text-gray-500 overflow-ellipsis '>{item.value}</a>
                                                            {/* <span className='text-gray-500 prose ' dangerouslySetInnerHTML={{ __html: item.value }} /> */}
                                                        </div>}
                                                    </>
                                                )
                                            })}
                                            {/* <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>fklsfjklsfjklsfjklsfjsklfjsklfskflmsnfklsdmfklsdmldfcdklsckdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd</p> */}
                                            {/* <p className='inline-block w-[200px] whitespace-normal overflow-hidden overflow-ellipsis'>https://www.godrejproperties.com/bangalore/residential/godrejananda/overview</p> */}
                                        </div>

                                        <div className='w-[50%] sm:w-[30%] mt-2'>
                                            {AllData.data?.detailsTab?.length > 0 && AllData.data?.detailsTab?.map((item, index) => {
                                                return (
                                                    <>
                                                        {(index + 1) % 3 == 0 && index < 6 && <div key={index} className='mt-1'>
                                                            <span className=''>{item.label}: </span>
                                                            {/* <span className='text-gray-500 '>{item.value}</span> */}
                                                            <span className='text-gray-500 prose ' dangerouslySetInnerHTML={{ __html: item.value }} />
                                                        </div>}
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className='w-[50%] sm:w-[30%] mt-2'>
                                            {AllData.data?.detailsTab?.length > 0 && AllData.data?.detailsTab?.map((item, index) => {
                                                return (
                                                    <>
                                                        {(index + 2) % 3 == 0 && <div key={index} className='mt-1'>
                                                            <span className=''>{item.label}: </span>
                                                            {/* <span className='text-gray-500 '>{item.value}</span> */}
                                                            <span className='text-gray-500 prose ' dangerouslySetInnerHTML={{ __html: item.value }} />
                                                        </div>}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {AllData.data?.amenitiesItems && !AllData.data?.unitConfigurationTab && <div className='mt-5 pb-5'>
                                    <p className={styles.title4 + 'ml-1'}>Amenities</p>
                                    <div className='flex flex-wrap justify-between mt-4'>
                                        {/* AllData.data?.detailsTab?.amenitiesItems? */}
                                        {/* Amenities */}
                                        {AllData.data?.amenitiesItems?.map((item, index) => {
                                            return (
                                                // <div className='prose flex w-[50%] min-w-[180px] sm:w-[30%]' dangerouslySetInnerHTML={{__html:item}}/>
                                                <div key={index} className='flex w-[50%] min-w-[180px] sm:w-[30%]'>
                                                    <button className='w-8 h-8 p-1 flex justify-center'> {item.icon === '' ? <MenuIcon classname={'h-4 w-4 mt-1'} />
                                                        : <i class={item.icon + ' text-gray-600 text-sm'}></i>
                                                    }
                                                    </button>
                                                    <span className='text-gray-700  mt-[1px]'>{item.text}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>}
                            </div>
                            <div ref={Description} className='bg-white shadow-md px-[2%] py-5 mt-10 scroll-mt-20'>
                                <p className={styles.title4}>{AllData.data?.scoietyName ? `About ${AllData.data?.scoietyName}` : 'Property Brief'}</p>
                                <div className='mt-1 prose min-w-full' dangerouslySetInnerHTML={{ __html: AllData.data?.descriptionTab?.description }} />
                                {/* <p className='mt-2 text-[0.9rem] text-gray-700 whitespace-pre-line'>{AllData.data?.descriptionTab?.description}</p> */}
                                {AllData.data?.descriptionTab?.address && <div className='mt-5'>
                                    <p className={styles.title4}>Map Location</p>
                                    <div className='flex flex-wrap gap-2 mt-2 items-start w-[80%]'>
                                        {AllData.data?.descriptionTab?.address?.map((item, index) => {
                                            return (
                                                <span key={index} className='w-[250px]'>{item.label} : <span className='text-gray-500 text-[0.9rem] font-semibold'>{item.value}</span></span>
                                            )
                                        })}
                                    </div>
                                </div>}
                            </div>

                            {AllData.data?.descriptionTab?.aboutBuilder && <div ref={AboutBuilder} className='bg-white shadow-md px-[2%] py-5 mt-10 scroll-mt-20'>
                                <p className={styles.title4}>About {AllData.data?.userDetails?.name}</p>
                                {/* <p className='mt-2 text-[0.9rem] text-gray-700'>{aboutBuilder}</p> */}
                                <div className='mt-2 prose prose-sm sm:prose-base min-w-[100%]' dangerouslySetInnerHTML={{ __html: AllData.data?.descriptionTab?.aboutBuilder }} />
                            </div>}
                            {AllData.data?.amenitiesItems && AllData.data?.unitConfigurationTab && <div className='bg-white shadow-md px-[2%] py-5 mt-10'>
                                <p className={styles.title4 + 'ml-1'}>Amenities</p>
                                <div className='flex flex-wrap justify-between mt-4'>
                                    {/* AllData.data?.detailsTab?.amenitiesItems? */}
                                    {/* Amenities */}
                                    {AllData.data?.amenitiesItems?.map((item, index) => {
                                        return (
                                            // <div className='prose flex w-[50%] min-w-[180px] sm:w-[30%]' dangerouslySetInnerHTML={{__html:item}}/>
                                            <div key={index} className='flex w-[50%] min-w-[180px] sm:w-[30%]'>
                                                <button className='w-8 h-8 p-1 flex justify-center'> {item.icon === '' ? <MenuIcon classname={'h-4 w-4 mt-1'} />
                                                    : <i class={item.icon + ' text-gray-600 text-sm'}></i>
                                                }
                                                </button>
                                                <span className='text-gray-700  mt-[1px]'>{item.text}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                            {AllData.data?.unitConfigurationTab?.length > 0 && <div className='bg-white shadow-md px-[2%] py-5 mt-10'>
                                <p className={styles.title4}>{AllData.data?.scoietyName} Configuration</p>
                                <div className='mt-5 flex justify-between bg-gray-800 text-white text-sm font-semibold p-2'>
                                    <div className='w-full text-center'>Unit Types</div>
                                    <div className='w-full text-center'>Built-Up Area</div>
                                    <div className='w-full text-center'>Price</div>
                                    <div className='w-full text-center'>Floor Plans</div>
                                    <div className='w-full text-center'>Live-in Tour</div>
                                </div>
                                {AllData.data?.unitConfigurationTab.map((item, index) => {
                                    return (
                                        <div className='flex justify-between text-sm text-gray-600 p-2'>
                                            <div className='w-full'>
                                                <p>{item.unit}</p>
                                                <p className='text-gray-500'>Availabity: {item.unitAvailability}</p>
                                            </div>
                                            <div className='w-full'>
                                                <p className='text-center'>{item.unitBuiltUpArea}</p>
                                            </div>
                                            <div className='w-full'>
                                                <div className='min-w-[100%] text-center' dangerouslySetInnerHTML={{ __html: item.unitPrice }} />
                                                {/* <p className='text-center'>{item.unitBuiltUpArea}</p> */}
                                            </div>
                                            <div className='w-full'>
                                                <p className='text-center'>{item.catpetArea}</p>
                                            </div>
                                            <div className='w-full'>
                                                <p className='text-center'>{item.liveInTour ? <i class="fa-solid fa-film hover:opacity-70 opacity-90 cursor-pointer"
                                                    onClick={() => setCurrPlayVideo(item.liveInTour)}> </i>
                                                    : 'NA'}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}

                            {currPlayVideo && <div className='fixed h-screen w-screen left-0 top-0 flex items-center justify-center z-50 bg-black bg-opacity-70'
                            // onClick={onclickVideoPopup}
                            >
                                <div className='relative flex items-center justify-center w-1/2 mt-14 border-dotted border-[1px] border-white p-[1px]' >
                                    <i class="fa-solid fa-xmark absolute text-2xl -top-8 -right-5 text-white cursor-pointer"
                                        onClick={() => setCurrPlayVideo(null)}></i>
                                    <video autoPlay controls width="" className='w-full'>
                                        {/* C:\fakepath\file_example_MP4_480_1_5MG.mp4 */}
                                        <source
                                            // src="https://www.truehomes24.com/assets/dynamic/unit-configuration/6636/5dd451239ce95933b2be48ec8a0aa057.mp4"
                                            src={currPlayVideo}
                                            type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                            </div>}

                            <div ref={Gallery} className={(AllData.data?.galleryTab?.images ? 'bg-white' : 'bg-gray-800 text-white') + ' mt-10  shadow-md px-[2%] py-5 w-full scroll-mt-20'}>
                                {AllData.data?.galleryTab?.images ? <p className={styles.title3 + 'mb-5'}>Gallery</p>
                                    :
                                    <div className='flex flex-wrap gap-2 font-semibold border-b-[1px] border-b-white mb-5'>
                                        {AllData.data?.galleryTab?.PropertyImages && <button
                                            onClick={() => setGalleryImges({ images: AllData.data?.galleryTab?.PropertyImages, tabName: 'PropertyImages' })}
                                            className={(galleryImages.tabName == 'PropertyImages' ? 'border-b-orange-600 border-b-2 -mb-[1px]' : '') + ' p-2'}>
                                            Gallery
                                        </button>}
                                        {AllData.data?.galleryTab?.MasterPlanImages && <button
                                            onClick={() => setGalleryImges({ images: AllData.data?.galleryTab?.MasterPlanImages, tabName: 'MasterPlanImages' })}
                                            className={(galleryImages.tabName == 'MasterPlanImages' ? 'border-b-orange-600 border-b-2 -mb-[1px]' : '') + ' p-2'}>
                                            Master Plan
                                        </button>}
                                        {AllData.data?.galleryTab?.FloorPlanImages && <button
                                            onClick={() => setGalleryImges({ images: AllData.data?.galleryTab?.FloorPlanImages, tabName: 'FloorPlanImages' })}
                                            className={(galleryImages.tabName == 'FloorPlanImages' ? 'border-b-orange-600 border-b-2 -mb-[1px]' : '') + ' p-2'}>
                                            Floor Plan
                                        </button>}
                                        {AllData.data?.galleryTab?.RouteMapImages && <button
                                            onClick={() => setGalleryImges({ images: AllData.data?.galleryTab?.RouteMapImages, tabName: 'RouteMapImages' })}
                                            className={(galleryImages.tabName == 'RouteMapImages' ? 'border-b-orange-600 border-b-2 -mb-[1px]' : '') + ' p-2'}>
                                            Route Map
                                        </button>}
                                    </div>
                                }
                                <Carousel
                                    swipeable={true}
                                    draggable={false}
                                    responsive={responsive}
                                    // ssr={true}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    transitionDuration={2000}
                                    afterChange={(Ind) => {
                                        setCurrSlide(Ind - 1)
                                    }}
                                >
                                    {galleryImages?.images?.length > 0 && galleryImages?.images?.map((item, index) => {
                                        return (
                                            <div onClick={() => setOpen(true)} key={index} className='p-2 hover:cursor-pointer'>
                                                <img alt='' src={item} className='h-[450px] w-full rounded-xl' />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                                <div className='flex justify-end mr-2'>
                                    <p className={(AllData.data?.galleryTab?.images ? 'text-gray-800' : 'text-white') + ' font-semibold'}>{currSlide}/{galleryImages?.images?.length}</p>
                                </div>
                                {/* <ImageGallery
                                    items={AllData.gallery?.map((item, index) => {
                                        return {
                                            original: item.image,
                                            thumbnail:item.image,
                                            thumbnailHeight:'80'
                                        }
                                    })}
                                    autoPlay
                                    showBullets
                                /> */}

                                <Lightbox
                                    open={open}
                                    plugins={[Thumbnails, Download, Fullscreen, Zoom]}
                                    close={() => setOpen(false)}
                                    slides={galleryImages?.images?.map((item, index) => {
                                        return { src: item }
                                    })}
                                // slides={[
                                //     {
                                //         src: rightSectionData[0].image,
                                //         width: 3840,
                                //         height: 2560,
                                //         srcSet: rightSectionData.map((item, index) => {
                                //             return { src: item.image }
                                //         })
                                //     }
                                // ]}

                                />
                                {/* <LightGallery
                                 speed = {500}
                                 pligins={[lgThumbnail]}
                                >
                                     {rightSectionData.map((item, index) => {
                                        return (
                                            <a href={item.image} >
                                                <img alt='' src={item.image} />
                                            </a>
                                        )
                                    })}

                                </LightGallery> */}

                            </div>
                            {
                                // AllData.data?.nearByTab?.nearby && 
                                <div ref={Nearby} className='mt-10 bg-white shadow-md px-5 py-5 mb-10 scroll-mt-20'>
                                    <p className={styles.title3}>What's Nearby?</p>
                                    <div className='mt-5'>
                                        {/* {AllData.data?.nearByTab?.nearby?.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.title5 + 'mt-3'}>
                                                <button className='mr-4 h-7 w-7 opacity-85 bg-orange-500 rounded-md' ><i class={item.icon + ' text-white text-sm'}></i></button>
                                                <span>{item.name} - {item.quantity}</span>
                                            </div>
                                        )
                                    })} */}
                                        {/* <select name="" className={styles.input + 'mt-1 text-gray-500 '} value={JSON.stringify(nearByType)} onChange={(e) => {
                                            let item = JSON.parse(e.target.value);
                                            setNearByType(item);
                                            performNearbySearch(center, item);
                                        }}>
                                            <option value={JSON.stringify({ label: '', value: '' })}>Select NearBy Type</option>
                                            {nearByPlacesType.map((item, index) => {
                                                return (
                                                    <option key={index} className='text-sm sm:text-base text-gray-500' value={JSON.stringify(item)}>{item.label}</option>
                                                )
                                            })}
                                        </select> */}
                                        {console.log("AllData====>", AllData)}
                                        {AllData?.data?.location && <NearByLocation address={AllData.data.location} nearTo={true} showMap={true} />}
                                    </div>
                                </div>}
                            {/* <div className='mt-5 lg:w-[80%]'>
                                {nearbyPlaces.map((item, index) => {
                                    return (
                                        <div className='grid grid-cols-2 gap-5 mt-2  px-2 border-b-[1px] border-b-gray-200'>
                                            <div>{item.name}</div>
                                            <div className='text-right'>{item.distance} KM</div>
                                        </div>
                                    )
                                })}
                            </div> */}
                            {(AllData.data?.faqtab?.length > 0 || AllData.data?.faqtab?.faqs?.length > 0) && <div ref={FAQ} className='mt-10 bg-white shadow-md px-5 py-5 mb-10 scroll-mt-20'>
                                <FAQs Data={AllData.data?.faqtab?.length ? AllData.data?.faqtab : AllData.data?.faqtab?.faqs} />
                            </div>}
                        </div>

                        <div className='w-full lg:w-[33%] xl:max-w-[400px]'>
                            {AllData?.featuredProperty?.length > 0 && <RecentViewCard title={'Featured Property'} Data={AllData?.featuredProperty} currency={AllData.data.CurrencyClass} />}

                            <RightListCard title={'Recently Added Property'} data={AllData?.recentlyAddedProperty} currency={AllData?.data?.CurrencyClass} />
                            <RightListCard title={'Recent Blog'} data={AllData?.recentBlogs} currency={AllData?.data?.CurrencyClass} />
                        </div>
                    </div>
                    {AllData.similarListing && <div className='mt-[50px]'>
                        <SimilarListing type={'Similar Listings'} Data={AllData.similarListing} />
                    </div>}
                    {AllData.otherProjects?.length > 0 && <div ref={OtherProjects} className='mt-[50px]'>
                        <PropertySlider type={`Other Projects by ${AllData.data?.userDetails?.name}`} Data={AllData.otherProjects} />
                    </div>}

                </div>
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default ProjectDetails;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1200 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 764 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 764, min: 0 },
        items: 1,
    },
};
